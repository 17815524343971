module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Leticia Ortiz, Escritora","short_name":"leticiaortizescritora","start_url":"/","background_color":"#ffffff","theme_color":"#ffebef","display":"minimal-ui","icon":"src/images/firma.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"631e0b83d671e98c83b53f2b683e9dc2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-129053715-2","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
